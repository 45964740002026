.quran-container {
    text-align: center;
    padding: 20px;
    max-width: 900px;
    margin: auto;
    background: #EBFAFA;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  /* 📌 Sure Seçimi (Dropdown) Stil Güncellemesi */
.top-bar select {
    width: 100%;
    max-width: 250px;
    padding: 12px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 10px; /* 📌 Oval Kenarlar */
    background-color: white;
    color: #333;
    font-weight: bold;
    outline: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  /* 📌 Hover Efekti */
  .top-bar select:hover {
    border-color: #27ae60;
  }
  
  /* 📌 Odaklanınca (Tıklayınca) */
  .top-bar select:focus {
    border-color: #2ecc71;
    box-shadow: 0 0 8px rgba(46, 204, 113, 0.5);
  }
  
  /* 📌 Seçeneklerin Tasarımı */
  .top-bar select option {
    background-color: white;
    color: #333;
    font-weight: bold;
    padding: 10px;
  }
  
  /* 📌 Mobil İçin */
  @media (max-width: 768px) {
    .top-bar select {
      width: 90%;
    }
  }
  
  .ayah-selector {
    display: flex;
    align-items: center;
  }
  
  .ayah-selector input {
    width: 50px;
    text-align: center;
    margin: 0 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .verse-content {
    margin-top: 20px;
  }
  
  .arabic-text {
    font-size: 30px;
    font-family: 'Amiri', serif;
    direction: rtl;
    color: #2c3e50;
    font-weight: bold;
    padding: 10px;
  }
  
  .transliteration {
    font-size: 18px;
    color: #555;
  }
  
  .verse-translation {
    font-size: 22px;
    font-weight: bold;
    color: #2980b9;
    margin-bottom: 10px;
  }
  
  .audio-controls {
    margin-top: 15px;
  }
  
  button {
    padding: 8px 12px;
    margin: 5px;
    cursor: pointer;
    border: none;
    background: #27ae60;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  button:hover {
    background: #2ecc71;
  }
  
  .word-analysis {
    margin-top: 20px;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 10px;
  }
  
  .word-analysis table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .word-analysis th, .word-analysis td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .word-analysis th {
    background-color: #f1f1f1;
  }
  
  .error-message {
    color: red;
    font-size: 18px;
    font-weight: bold;
  }
  .audio-progress {
    width: 100%;
    margin: 10px 0;
  }
  
  .progress-bar {
    width: 100%;
    height: 6px;
    background: #ddd;
    border-radius: 5px;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background: #27ae60;
    border-radius: 5px;
    transition: width 0.3s linear;
  }
  
  .arabic-text {
    font-size: 24px;
    text-align: center;
    margin: 20px 0;
  }
  
  .arabic-text .highlight {
    color: #e74c3c;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
  }
  
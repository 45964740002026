/* Tüm alanı saran kapsayıcı */
.support-curved-area {
    width: 100%;
    padding: 40px 0; /* Yukarı-aşağı boşluk */
    position: relative;
    overflow: hidden; /* İçerideki mutlak konumlu simgeler taşmasın */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Arka planı kavisli yapacağımız kapsayıcı */
  .support-curved-bg {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    background: #D9F3FB; /* Arka plan rengi (lila tonları) */
    border-radius: 40px; /* Kenarları epey yuvarlak */
    position: relative;
    text-align: center;
  }
  
  /* Metin içerikleri */
  .support-curved-content {
    z-index: 1; /* Simgelerin altına düşmemesi için */
    position: relative;
  }
  
  .support-curved-content h2 {
    font-size: 28px;
    color: #242F6C; /* Arka planla kontrast oluşturması için beyaz */
    margin-bottom: 16px;
    font-weight: 600;
  }
  
  .support-curved-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #242F6C;
  }
  
  
  /* Küçük simgeler (icon-1, icon-2, icon-3) */
  .floating-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #ffffff; /* Örnek, ya da bir simge resmi koyabilirsiniz background-image ile */
    border-radius: 50%;
    opacity: 0.2; /* Hafif saydamlık */
    z-index: 0;
  }
  
  /* Farklı konumlar için */
  .icon-1 {
    top: 20px;
    left: 30px;
  }
  .icon-2 {
    bottom: 30px;
    right: 60px;
  }
  .icon-3 {
    top: 50%;
    left: 80%;
    transform: translateY(-50%);
  }
  
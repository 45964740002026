/* =====================================================
   1. Service Area & Imsak Schedule Styles
===================================================== */

/* Service alanı */
.service-area {
  padding: 60px 0;
}

.service-area h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
}

/* Mobilde tablo taşarsa yatay kaydırma */
.table-container {
  width: 100%;
  overflow-x: auto;
}

/* İmsak tablo düzeni */
.imsak-schedule {
  width: 75%;
  min-width: 600px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 8px; /* Satırlar arası dikey boşluk */
  font-family: "Open Sans", sans-serif;
}

/* Tablo başlık satırı */
.imsak-schedule thead th {
  background-color: #17A4AD;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
}

/* Tablo gövdesi */
.imsak-schedule tbody tr {
  border: none;
  transition: background-color 0.3s;
  border-radius: 8px;
}

/* Satır renkleri */
.imsak-schedule tbody tr:nth-child(odd) td {
  background-color: #ffffff;
}

.imsak-schedule tbody tr:nth-child(even) td {
  background-color: #e6e6e67e;
}

/* Hücre stil ayarları */
.imsak-schedule th,
.imsak-schedule td {
  padding: 12px 16px;
  text-align: center;
  border: none;
  font-size: 14px;
  color: #333;
  border-radius: 8px;
}

/* Hover efekti */
.imsak-schedule tbody tr:hover td {
  background-color: #00d5b913;
}

/* Özel gün satırları (ör. Kadir Gecesi) */
.special-event td {
  background-color: #00BFD6 !important;
  color: #fff !important;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  border-radius: 8px;
}

/* Responsive düzenleme: küçük ekranlarda imsak tablo */
@media (max-width: 767px) {
  .imsak-schedule {
    width: 100%;
    min-width: auto;
  }
}
@media (max-width: 991px) {
  .imsak-schedule {
    width: 100%;
    min-width: auto;
  }
}
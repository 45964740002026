/* Genel pilars resim stili - responsive olarak ayarlandı */
.pilarts-img {
    width: 100%;
    max-width: 280px;  /* Varsayılan en geniş boyut */
    height: auto;
    aspect-ratio: 1 / 1; /* Kare görünüm için */
    overflow: hidden;
    border-radius: 50%;
    background-color: rgb(248, 254, 254);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 auto;
  }
  
  /* Pilars alanında, sıralamaya göre arka plan renkleri */
  .pilars-area .grid:nth-child(2) .pilarts-img {
    background-color: #fcf4f5;
  }
  
  .pilars-area .grid:nth-child(3) .pilarts-img {
    background-color: #f9f8fe;
  }
  
  .pilars-area .grid:nth-child(4) .pilarts-img {
    background-color: #fcf3f8;
  }
  
  .pilars-area .grid:nth-child(5) .pilarts-img {
    background-color: #f9fefa;
  }
  
  /* Başlık gradientleri */
  .pilars-area .grid:nth-child(1) .pilars-text h2 {
    background: linear-gradient(to left, #00b3d6, #00ded8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .pilars-area .grid:nth-child(2) .pilars-text h2 {
    background: linear-gradient(to left, #ff7e8f, #ffa773);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .pilars-area .grid:nth-child(3) .pilars-text h2 {
    background: linear-gradient(to left, #6368f3, #c868ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .pilars-area .grid:nth-child(4) .pilars-text h2 {
    background: linear-gradient(to left, #ff77b7, #fbaedd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .pilars-area .grid:nth-child(5) .pilars-text h2 {
    background: linear-gradient(to left, #17cf89, #8cd080);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Genel pilars başlık stil ayarı */
  .pilars-area .grid .pilars-text h2 {
    font-size: 30px;
  }
  
  /* Resim hover efekti */
  .pilarts-img img {
    transform: scale(1);
    transition: all 0.3s;
    width: 100%;
  }
  
  .pilarts-img:hover img {
    transform: scale(0.9);
    transition: all 0.3s;
  }
  
  /* Pilars alanı düzeni: Flexbox ile grid yapı */
  .pilars-area .row-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
  }
  
  .pilars-area .row-grid .grid {
    flex: 0 0 20%;
    max-width: 20%;
    box-sizing: border-box;
  }
  
  /* Pilars alanı genel ayarları */
  .pilars-area {
    padding: 80px 170px;
    text-align: center;
  }
  
  .pilars-item {
    text-align: center;
  }
  
  /* Pilars metin stili */
  .pilars-text {
    margin-top: 40px;
    text-align: center;
    padding: 10px 0;
  }
  
  .pilars-text h2 {
    font-size: 20px;
    margin-bottom: 8px;
    color: #333;
  }
  
  .pilars-text p {
    font-size: 16px;
    color: #00d5b9;
    font-weight: bold;
    margin: 0;
  }
  
  .pilars-text p strong {
    color: #00d5b9;
    font-size: 18px;
    margin-right: 5px;
  }
  
  .pilars-text p span {
    display: block;
    font-size: 14px;
    color: #333;
    margin-top: 4px;
  }
  
  /* =====================================================
     3. Responsive Ayarlamalar (Pilars Area)
  ===================================================== */
  
  @media (max-width: 1800px) {
    .pilarts-img {
      max-width: 195px;
    }
  }
  
  @media (max-width: 1400px) {
    .pilarts-img {
      max-width: 165px;
    }
  }
  
  @media (max-width: 1200px) {
    .pilars-area .row-grid .grid {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  
  @media (max-width: 991px) {
    .pilars-area .row-grid .grid {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .pilarts-img {
      max-width: 205px;
    }
    .pilars-area {
      padding: 80px 40px;
    }
  }
  
  @media (max-width: 767px) {
    .pilars-area .row-grid .grid {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .pilarts-img {
      max-width: 295px;
    }
    .pilars-item {
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 575px) {
    .pilarts-img {
      max-width: 235px;
    }
    .pilars-area {
      padding: 45px 20px 20px;
    }
  }
.floating-city-bubble-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99999;
}

.floating-city-bubble {
  background-color: #00d5b9;
  color: #fff;
  padding: 10px 16px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.floating-city-bubble:hover {
  transform: scale(1.05);
}

.floating-city-bubble .bubble-text {
  margin-top: 4px;
}

.floating-city-dropdown {
  margin-top: 8px;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.floating-city-dropdown select {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  font-size: 14px;
}

.floating-city-dropdown button {
  padding: 6px 12px;
  background: #00d5b9;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}
